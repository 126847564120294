<template>
  <div class="vtb-layout">
    <VtbHero />
    <router-view />
    <div class="bg-black">
      <VtbFooter />
    </div>
  </div>
</template>

<script>
import VtbHero from '@/components/VtbHero.vue';
import VtbFooter from '@/components/VtbFooter.vue';

export default {
  components: {
    VtbHero,
    VtbFooter,
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss">
.vtb-layout {
  background: #181921;
}

.vtb-container {
  @apply mx-16 laptop:mx-72;
}

.vtb-section-header {
  @apply text-24 leading-120p laptop:text-48 laptop:leading-44 font-vtb-demibold;
}
</style>
