export const formatTime = (dateA, isDate = false) => {
  const date = isDate ? dateA : new Date(dateA);
  return `${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}`;
};

export const formatDate = (dateString, withTime = false) => {
  const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];

  const date = new Date(dateString);
  const nowDate = new Date();

  if (withTime) {
    if (nowDate.getFullYear() === date.getFullYear()) {
      return `${date.getDate()} ${months[date.getMonth()]}, ${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}`;
    }

    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}, ${(`0${date.getHours()}`).slice(-2)}:${(`0${date.getMinutes()}`).slice(-2)}`;
  }

  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const dateSort = (dateKey, asc = false) => (a, b) => {
  const aDate = new Date(a[dateKey]);
  const bDate = new Date(b[dateKey]);
  return asc ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime();
};

export const roundHalf = (num) => Math.round(num * 2) / 2;

export const shuffle = (array) => {
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // eslint-disable-next-line no-param-reassign
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
};
