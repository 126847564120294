/* eslint-disable camelcase */
import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import VtbLayout from '../layouts/VtbLayout.vue';

import MainPage from '../pages/MainPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'VtbLayout',
    component: VtbLayout,
    children: [
      {
        path: '/',
        name: 'MainPage',
        component: MainPage,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  window.location.replace('https://www.vtbcareer.com/vacancy/');
  store.dispatch('fetchUser');
  next();
});

export default router;
