<template>
  <div class="vtb-share">
    <a :href="shareVK" target="_blank" class="vtb-share__item">
      <img src="~@/assets/vk.svg" alt="">
    </a>
    <a :href="shareFB" target="_blank" class="vtb-share__item">
      <img src="~@/assets/fb.svg" alt=""></a>
    <a :href="shareTwitter" target="_blank" class="vtb-share__item">
      <img src="~@/assets/twitter.svg" alt="">
    </a>
  </div>
</template>

<script>
export default {
  props: {
    shareUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    shareVK() {
      return `https://vk.com/share.php?url=${this.shareUrl}`;
    },
    shareFB() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.shareUrl}`;
    },
    shareTwitter() {
      return `https://www.twitter.com/share?url=${this.shareUrl}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-share {
  @apply flex justify-center;
  &__item {
    &:not(:first-child) {
      @apply ml-8;
    }

    & img {
      @apply w-62 h-62;
    }
  }
}
</style>
