<template>
  <div class="vtb-disclaimer">
    <div class="container mx-auto">
      <div class="vtb-disclaimer__text">
        Гарантируем самый <VtbAccentTooltip :tooltip="tooltip">
          приятный процесс поиска
        </VtbAccentTooltip><br /> работы через карьерную консультацию
      </div>
      <div class="vtb-disclaimer__features">
        <div class="vtb-disclaimer__feature">
          <div class="vtb-disclaimer__feature-icon">
            <img src="/edit.svg" />
          </div>
          <div class="vtb-disclaimer__feature-head">
            1. Подай заявку →
          </div>
        </div>
        <div class="vtb-disclaimer__feature">
          <div class="vtb-disclaimer__feature-icon">
            <img src="/headphones.svg" />
          </div>
          <div class="vtb-disclaimer__feature-head">
            2. Пройди онлайн-консультацию →
          </div>
        </div>
        <div class="vtb-disclaimer__feature">
          <div class="vtb-disclaimer__feature-icon">
            <img src="/message-square.svg" />
          </div>
          <div class="vtb-disclaimer__feature-head">
            3. Получи пре-оффер или обратную связь в тот же день
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VtbAccentTooltip from '@/components/VtbAccentTooltip.vue';

export default {
  data() {
    return {
      tooltip: 'Это не ссылка 😅<br/>Просто процесс поиска очень приятный',
    };
  },
  components: {
    VtbAccentTooltip,
  },
};
</script>

<style lang="scss" scoped>
.vtb-disclaimer {
  background: linear-gradient(0deg, #0E0E0E, #0E0E0E), #181921;
  @apply pt-76 tablet:pt-164 pb-44;

  &__text {
    @apply text-white text-24 tablet:text-40 leading-32
      tablet:leading-62 font-vtb-demibold text-center h-316 tablet:mb-76;
  }

  &__features {
    @apply pt-44 pb-68 border-t border-white border-opacity-20 grid grid-cols-3 gap-x-76;
    @apply hidden tablet:grid;
  }

  &__feature-head {
    @apply text-white text-24 leading-32 mt-18 font-vtb-regular;
  }

  &__feature-text {
    @apply text-white text-18 leading-22 mt-16 font-vtb-regular text-opacity-70;
  }
}
</style>
