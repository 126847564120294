<template>
  <article class="vtb-main-page relative z-10">
    <VtbDisclaimer />
    <VtbInfo />
    <VtbHow />
    <!--<VtbCompare />-->
    <VtbLetsTry />
    <!--<VtbFeedback />-->
    <VtbShareBlock />
    <VtbModalSuccess />
    <VtbModalSubscribe />
  </article>
</template>

<script>
import VtbDisclaimer from '@/components/VtbDisclaimer.vue';
import VtbInfo from '@/components/VtbInfo.vue';
import VtbHow from '@/components/VtbHow.vue';
// import VtbCompare from '@/components/VtbCompare.vue';
import VtbLetsTry from '@/components/VtbLetsTry.vue';
// import VtbFeedback from '@/components/VtbFeedback.vue';
import VtbShareBlock from '@/components/VtbShareBlock.vue';
import VtbModalSuccess from '@/components/VtbModalSuccess.vue';
import VtbModalSubscribe from '@/components/VtbModalSubscribe.vue';

export default {
  components: {
    VtbDisclaimer,
    VtbInfo,
    VtbHow,
    // VtbCompare,
    VtbShareBlock,
    // VtbFeedback,
    VtbLetsTry,
    VtbModalSuccess,
    VtbModalSubscribe,
  },
  mounted() {
    const { result } = this.$route.query;
    if (result && result === 'success_created') {
      this.$modal.show('subscribe-modal');
    }
  },
};
</script>
