<template>
  <div class="vtb-share-block">
    <div class="container mx-auto">
      <div class="vtb-share-block__header z-10 relative">
        расскажи друзьям,<br/>
        которые ищут работу
      </div>
      <VtbShare class="mt-62 z-10 relative" />
    </div>
  </div>
</template>

<script>
import VtbShare from '@/components/VtbShare.vue';

export default {
  components: {
    VtbShare,
  },
};
</script>

<style lang="scss" scoped>
.vtb-share-block {
  @apply pt-44 tablet:pt-88 pb-108 relative z-10 overflow-hidden;

  &::before {
    content: '';
    position: absolute;
    height: 150px;
    background: linear-gradient(270deg, #3EB9FF 0%, #0057C1 97.6%);
    filter: blur(100px);
    z-index: 0;
    bottom: 0;
    width: 100%;
    @apply w-full tablet:left-0 tablet:h-148;
  }

  &__header {
    @apply text-white text-28 leading-40 tablet:text-48
      tablet:leading-72 uppercase text-center font-vtb-cond-bold;
  }
}
</style>
