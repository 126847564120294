<template>
<div class="container bg-black  mx-auto">
  <footer class="vtb-footer">
      <div class="vtb-footer__logos">
        <a class="vtb-footer__logo" href="https://geecko.ru/" target="_blank">
          <img style="height: 24px;" :src="$options.geeckoLogo" />
        </a>
      </div>
      <div class="vtb-footer__middle">
        <div class="vtb-footer__links">
          <a class="vtb-footer__link"
            href="/docs/policy.pdf" target="_blank">Политика конфиденциальности</a>
          <a
            class="vtb-footer__link"
            href="/docs/agreement.pdf" target="_blank">Пользовательское соглашение</a>
        </div>
        <div class="vtb-footer__legal">
            ООО «Смарт Гико. 125008, Россия, г. Москва, проезд Черепановых,
            д. 36, ОГРН: 1187746655359, ИНН/КПП: 7743264341/774301001
          </div>
      </div>
      <div class="flex-1"></div>
      <div class="vtb-footer__right">
        <div class="vtb-footer__copyright">
          © 2021 <span class="tablet:hidden">Права защищены</span>
        </div>
      </div>
  </footer>
  </div>
</template>

<script>
import vtbLogoBlue from '@/assets/vtb-logo.svg';
import geeckoLogo from '@/assets/geeckoLogo.svg';

export default {
  vtbLogoBlue,
  geeckoLogo,
};
</script>

<style lang="scss" scoped>
.vtb-footer {
  @apply flex flex-col tablet:flex-row;
  @apply py-24;

  &__logos {
    @apply flex;
  }

  &__logo {
    @apply w-88;
    &:not(:first-child) {
      @apply ml-28;
    }
  }

  &__middle {
    @apply laptop:w-436;
    @apply mt-32 flex flex-col tablet:mt-0 tablet:ml-56 laptop:ml-60;
  }

  &__links {
    @apply flex flex-col tablet:flex-row;
  }

  &__legal {
    @apply text-10 leading-16;
    @apply mt-24 laptop:text-12 laptop:leading-18 text-white text-opacity-40 font-tt-hoves;
  }

  &__link {
    @apply text-white text-14 leading-28 font-tt-hoves;
    &:not(:first-child) {
      @apply mt-8 tablet:ml-24 tablet:mt-0;
    }
  }

  &__copyright {
    @apply text-10 leading-16;
    @apply mt-18 tablet:mt-0 text-footer-gray font-tt-hoves text-10 leading-16;
  }
}
</style>
