import Vue from 'vue';
import VueGtm from 'vue-gtm';
import VTooltip from 'v-tooltip';
import VModal from 'vue-js-modal';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/fonts.scss';
import './assets/tooltip.css';
import './assets/tailwind.css';

axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api`;
axios.defaults.withCredentials = true;

if (process.env.VUE_APP_GTM && process.env.VUE_APP_GTM.length) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM,
  });
}

const uriParams = new URLSearchParams(window.location.search);
const authToken = uriParams.get('token');

if (authToken) {
  store.commit('SET_TOKEN', authToken);
}

Vue.config.productionTip = false;

Vue.use(VTooltip);
Vue.use(VModal);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#vtb-app');
