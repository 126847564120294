<template>
  <span v-tooltip="tooltipOptions"
    class="vtb-accent"
    :class="{'vtb-accent--hovered': hovered}"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    tooltip: String,
  },
  data() {
    return {
      hovered: false,
    };
  },
  computed: {
    tooltipOptions() {
      return {
        content: this.tooltip,
        trigger: 'hover click',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-accent {
  @apply bg-medium-blue rounded-xl px-4 cursor-pointer transition-all;

  &--hovered {
    background: #2F3141;
  }
}
</style>
