var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.to)?_c('button',{staticClass:"vtb-button",class:{
    'vtb-button--icon': !!_vm.$slots.icon,
    'vtb-button--small' : _vm.isSmall,
    'vtb-button--disabled' : _vm.disabled,
    'vtb-button--success' : _vm.isSuccess,
    'vtb-button--blue' : _vm.isBlue,
    'vtb-button--dark' : _vm.isDark,
    'vtb-button--interactive': !_vm.disabled && !_vm.isSuccess,
    'rounded-3xl': _vm.isRounded,
    'rounded': !_vm.isRounded
  },on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("icon"),_c('span',{staticClass:"vtb-button__text"},[_vm._t("default")],2)],2):_c('router-link',{staticClass:"vtb-button vtb-button--link",class:{
    'vtb-button--icon': !!_vm.$slots.icon,
    'vtb-button--small' : _vm.isSmall,
    'vtb-button--disabled' : _vm.disabled,
    'vtb-button--success' : _vm.isSuccess,
    'vtb-button--blue' : _vm.isBlue,
    'vtb-button--interactive': !_vm.disabled && !_vm.isSuccess,
    'rounded-3xl': _vm.isRounded,
    'rounded': !_vm.isRounded
  },attrs:{"to":_vm.to},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("icon"),_c('span',{staticClass:"vtb-button__text"},[_vm._t("default")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }