<template>
  <div class="vtb-hero">
    <div class="container mx-auto laptop:px-12">
      <div class="vtb-hero__middle relative z-10">
        <div class="vtb-hero__logo">
          <img :src="$options.vtbLogo" />
        </div>
        <div class="vtb-hero__text">
          <div class="vtb-hero__head">
            Карьерная консультация
          </div>
          <div class="vtb-hero__subhead">
            для айтишников
          </div>
        </div>
      </div>
      <VtbButton class="mt-24 tablet:mt-68 w-304 relative z-10" @click="submit" is-rounded>
        <template #icon><img src="/geecko-rounded.svg" /></template>
        Подать заявку бесплатно
      </VtbButton>
      <div class="vtb-hero__features">
        <div class="vtb-hero__feature">
          <div class="vtb-hero__feature-icon">
            <img src="/trending-up.svg" />
          </div>
          <div class="vtb-hero__feature-head">
            Узнай, куда развиваться
          </div>
          <div class="vtb-hero__feature-desc">
            Плох тот мидл, который не хочет стать<br />сеньором. Мы подскажем, куда расти
          </div>
        </div>
        <div class="vtb-hero__feature">
          <div class="vtb-hero__feature-icon">
            <img src="/briefcase.svg" />
          </div>
          <div class="vtb-hero__feature-head">
            Получи оффер от ВТБ
          </div>
          <div class="vtb-hero__feature-desc">
            Мы меняемся. Присоединяйся к команде<br />в уникальный момент
            цифровой трансформации второго по величине банка в России
          </div>
        </div>
        <div class="vtb-hero__feature">
          <div class="vtb-hero__feature-icon">
            <img src="/fire.png" />
          </div>
          <div class="vtb-hero__feature-head">
            Ты крутой, мы ждем тебя
          </div>
          <div class="vtb-hero__feature-desc">
            Наши рекрутеры ждут каждого отклика
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import geeckoLogo from '@/assets/geecko-logo.svg';
import vtbLogo from '@/assets/vtb-logo.svg';
import VtbButton from '@/components/VtbButton.vue';

export default {
  geeckoLogo,
  vtbLogo,
  props: {
    minimized: Boolean,
  },
  components: {
    VtbButton,
  },
  methods: {
    submit() {
      if (this.$store.state.currentUser) {
        this.$modal.show('success-modal');
      } else {
        this.$store.dispatch('login');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vtb-hero {
  @apply pt-16 tablet:pt-64 pb-24 laptop:pb-48 relative flex flex-col h-full overflow-hidden;
  background-color: #181921;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    height: 320px;
    background: linear-gradient(180deg, rgba(24, 25, 33, 0) 0%, #0F3C72 100%);
    z-index: 0;
    bottom: 0;
    @apply w-full tablet:h-300;
  }

  &__logo {
    @apply h-24 tablet:w-136;
    img {
      @apply h-full tablet:w-full tablet:h-auto;
    }
  }

  &__content {
    @apply flex flex-col laptop:flex-row ml-16 laptop:ml-72;
    z-index: 3;
  }

  &__middle {
    @apply z-10 relative flex flex-col tablet:flex-row-reverse justify-between;
  }

  &__bottom {
    @apply relative mt-42 laptop:mt-108 flex flex-col tablet:flex-row justify-between;
  }

  &__bottom-header {
    @apply text-18 leading-32 text-white font-vtb-demibold;
  }

  &__feature-icon {
    @apply w-40 h-40 tablet:w-72 tablet:h-72;
  }

  &__bottom-text {
    @apply mt-4 text-base leading-24 text-white font-vtb-light;
  }

  &__emoji-row {
    @apply mt-8 mr-8 flex justify-end;
  }

  &__head {
    @apply text-36 leading-46 text-white font-vtb-cond-demibold tablet:text-76 tablet:leading-90;
  }

  &__subhead {
    @apply flex items-center text-36 leading-46 text-accent;
    @apply font-vtb-cond-demibold laptop:text-76 laptop:leading-90;
  }

  &__badge {
    @apply px-12 py-8 ml-16 laptop:px-16 laptop:py-0;
    @apply bg-yellow text-black text-12 leading-16 font-vtb-cond inline-block;
    @apply laptop:text-20 laptop:leading-44;
    transform: rotate(-8.84deg);
  }

  &__text {
    @apply mt-40 tablet:mt-0;
  }

  &__texts {
    @apply pr-16 flex-1 laptop:pr-0 laptop:min-w-500;
  }

  &__title {
    @apply text-white text-56 leading-120p font-vtb-demibold mt-40;
    @apply tablet:mt-88 tablet:text-88;
  }

  &__desc {
    @apply flex mt-12;
  }

  &__desc-spacer {
    @apply hidden;
    @apply laptop:flex laptop:flex-1;
  }

  &__desc-content {
    @apply flex-1;
  }

  &__desc-content-text {
    @apply text-20 laptop:text-28 leading-150p text-white font-vtb-light;
  }

  &__features {
    @apply mt-48 tablet:mt-76 grid grid-cols-1
      gap-y-52 tablet:grid-cols-3 tablet:gap-x-44 relative z-10;
  }

  &__feature-head {
    @apply mt-8 tablet:mt-20 text-20 leading-40 text-white font-vtb-demibold;
  }

  &__feature-desc {
    @apply mt-4 text-base leading-26 text-white font-vtb-light;
  }
}

.vtb-emoji {
  @apply w-80 h-80 rounded-full flex items-center justify-center;
  @apply laptop:w-160 laptop:h-160;

  &__image {
    @apply w-52 h-52 laptop:w-108 laptop:h-108;
  }

  &--brain-out {
    @apply bg-brain-out;
    transform: rotate(-14.85deg);
  }

  &--fire {
    @apply bg-fire;
    transform: matrix(0.97, 0.22, -0.22, 0.97, 0, 0);
  }
}
</style>
