<template>
  <div class="vtb-lets-try">
    <div class="flex flex-col">
      <div class="container mx-auto">
        <div class="font-vtb-cond-bold text-white text-26 leading-32">Наши консультанты</div>
      </div>
      <div class="container mx-auto relative flex flex-col tablet:flex-row mt-28">
        <div class="tablet:w-372 laptop:w-600 desktop:w-784
          relative tablet:flex">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(c, idx) in shuffledConsultants" :key="idx">
              <div class="vtb-lets-try__consultant" :style="getImageStyle(c)">
              </div>
              <div class="vtb-lets-try__consultant-name">
                {{ c.firstName }}<br/>
                {{ c.lastName }}
              </div>
            </swiper-slide>
          </swiper>
          <div class="vtb-lets-try__slider-button" @click="nextSlide">
            <img src="/arrow-right.svg" />
          </div>
        </div>
        <div class="tablet:ml-88">
          <div class="vtb-lets-try__header">
            Давай попробуем
          </div>
          <div class="vtb-lets-try__text">
            Пара минут и мы займемся твоей карьерой
          </div>
          <VtbButton class="mt-58 mx-auto w-288 desktop:w-300 relative z-10" is-rounded
            @click="submit">
            <template #icon><img src="/geecko-rounded.svg" /></template>
            Подать заявку бесплатно
          </VtbButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

import geeckoLogo from '@/assets/geecko-logo.svg';
import VtbButton from '@/components/VtbButton.vue';

import { shuffle } from '@/utils';

export default {
  geeckoLogo,
  data() {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 30,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 'auto',
            spaceBetween: 20,
            loop: true,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
            loop: false,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 24,
            centeredSlides: false,
            loop: false,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 24,
            centeredSlides: false,
            loop: false,
          },
        },
        // Some Swiper option/callback...
      },
      currentConsultant: 0,
      consultants: [
        {
          img: 'daria.jpg',
          lastName: 'Землезина',
          firstName: 'Дарья',
        },
        /* {
          img: 'kristina.jpg',
          lastName: 'Мельникова',
          firstName: 'Кристина',
        },
        {
          img: 'denis.jpg',
          lastName: 'Мышев',
          firstName: 'Денис',
        },
        {
          img: 'ljudmila.jpg',
          lastName: 'Карамышева',
          firstName: 'Людмила',
        }, */
        {
          img: 'alexandra.jpg',
          lastName: 'Королькова',
          firstName: 'Александра',
        },
        {
          img: 'alexey.jpg',
          lastName: 'Палецкий',
          firstName: 'Алексей',
        },
        {
          img: 'valeria.jpg',
          lastName: 'Лысенкова',
          firstName: 'Валерия',
        },
        {
          img: 'ekaterina.jpg',
          lastName: 'Дворецкая',
          firstName: 'Екатерина',
        },
        {
          img: 'natalia.jpg',
          lastName: 'Кадоркина',
          firstName: 'Наталья',
        },
        {
          img: 'yulia.jpg',
          lastName: 'Одинец',
          firstName: 'Юлия',
        },
        /* {
          img: 'elizaveta.jpg',
          lastName: 'Чернышева',
          firstName: 'Елизавета',
        },
        {
          img: 'natalia.jpg',
          lastName: 'Чугреева',
          firstName: 'Наталья',
        }, */
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    VtbButton,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    shuffledConsultants() {
      return shuffle(this.consultants);
    },
  },
  methods: {
    getImageStyle({ img }) {
      return {
        background: `linear-gradient(180deg, rgba(25, 120, 208, 0) 0%,
          rgba(38, 95, 148, 0.18) 67.71%, rgba(27, 137, 238, 0.23) 100%), url(/consultants/${img})`,
        backgroundPosition: 'center center',
        backgroundSize: '100%',
      };
    },
    nextSlide() {
      if (this.swiper.activeIndex !== this.consultants.length - this.swiper.params.slidesPerView) {
        this.swiper.slideNext();
      } else {
        this.swiper.slideTo(0, 1000, false);
      }
    },
    submit() {
      if (this.$store.state.currentUser) {
        this.$modal.show('success-modal');
      } else {
        this.$store.dispatch('login');
      }
    },
  },
};
</script>

<style lang="scss">
.vtb-lets-try {
  @apply pt-28 tablet:pt-44 tablet:pb-32 relative;

  &__image {
    @apply flex justify-center z-10;
  }

  &__header {
    @apply mt-32 laptop:mt-0 text-white text-26 leading-32 font-vtb-cond-bold z-10;
  }

  &__text {
    @apply mt-18 text-18 leading-24 text-white font-vtb-light z-10;
  }

  &__consultant {
    @apply w-118 tablet:w-148 h-156 tablet:h-196 rounded-2xl;
  }

  &__consultant-name {
    @apply mt-16 text-base leading-22 text-white text-opacity-70 font-vtb-light;
  }

  &__slider-button {
    @apply h-196 border-white border border-opacity-20 rounded-2xl cursor-pointer;
    @apply flex flex-col items-center justify-center;
    @apply hover:bg-white hover:bg-opacity-10;
    @apply select-none;
    @apply hidden tablet:flex ml-24;
    min-width: 46px;
  }

  .swiper-slide {
    @apply w-118 tablet:w-148;
  }
}
</style>
