import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: any) => {
    const saveState = {
      authToken: null,
    };
    saveState.authToken = state.authToken;
    return saveState;
  },
});

export default new Vuex.Store({
  state: {
    allMeetups: [],
    currentMeetup: {},
    isAllMeetupsLoading: false,
    isCurrentMeetupLoading: false,
    currentUserMeetups: [],
    specializations: [],
    tgCode: 0,
    currentUser: null,
    authToken: null,
  },
  mutations: {
    SET_USER: (state, payload) => {
      state.currentUser = payload;
    },
    SET_TOKEN: (state, payload) => {
      state.authToken = payload;
      axios.defaults.headers.common.Authorization = `Bearer ${payload}`;
    },
  },
  getters: {
  },
  actions: {
    login() {
      window.location.href = `${process.env.VUE_APP_API_URL}/oauth/redirect`;
    },
    async fetchUser({ commit, state }) {
      try {
        if (state.authToken) {
          commit('SET_TOKEN', state.authToken);
        }
        const { data } = await axios.get('/users/current');
        commit('SET_USER', data.data);
      } catch (e) {
        commit('SET_USER', null);
      }
    },
  },
  modules: {
  },
  plugins: [vuexLocal.plugin],
});
