<template>
  <modal
    name="success-modal"
    :adaptive="true"
    classes="vtb-modal"
    :minWidth="288"
    :maxWidth="472"
    :minHeight="198"
    :maxHeight="226"
  >
    <div class="vtb-modal__container">
      <div class="vtb-modal__title">
        Вы уже отправили заявку
      </div>
      <div class="vtb-modal__buttons">
        <button class="vtb-modal__button" @click="$modal.hide('success-modal')">
          Понял
        </button>
      </div>
    </div>
    <button class="vtb-modal__close" @click="$modal.hide('success-modal')">
      <svg
        width="10"
        height="10"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41
            14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z"
          fill="currentColor"
        />
      </svg>
    </button>
  </modal>
</template>
<script>
export default {
  name: 'VtbModalSuccess',
};
</script>

<style lang="scss">
.vtb-modal {
  background: linear-gradient(180deg, rgba(24, 25, 33, 0) 0%,
    rgba(15, 60, 114, 0.53) 100%) #2A2C30;
  border-radius: 16px;

  &__close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    top: 8px;
    right: 8px;
    background: rgba(88, 89, 91, 0.5);
    border-radius: 4px;
    width: 16px;
    height: 16px;
    padding: 0px 0;
    color: #58595b;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
    @apply py-32;
  }

  &__title {
    @apply font-vtb-cond-bold text-26 leading-32 text-white;
  }

  &__button {
    @apply bg-medium-blue py-12 text-14 leading-18 w-156 text-white outline-none;
    @apply font-vtb-cond-bold focus:outline-none hover:bg-blue-hover;
    border-radius: 24px;
  }
}
</style>
